// This file contains configuration of the "Opsummering" view for the supplier.
// It lists question id's of questions on the second level in the questionnaire template
// to be excluded from the "Opsummering" view. Questions not in this list will be
// shown in the "Opsummering" view.

const excludedQuestions = [
  // Kategori 1
  'ae832146-821e-4d1b-a267-601c916e61ac', // Vejledning (Kategori 2)
  '73235af7-f4c2-4717-afcb-8d57f410a625', // Motivation
  // Kategori 2
  'cb469b45-d1e6-4bf5-91cd-f261a344a6e7', // Vejledning (Kategori 2)
  '17564fa8-cf30-4025-a800-d30779bf775c', // Motivation
];

export default excludedQuestions;
