<template>
  <div>
    <LoadingSpinner :visible="isLoading" loadingMessage="Henter tilbud" />
    <div v-if="!isLoading">
      <v-card v-if="tender" class="pa-3" color="#fff9ef">
        <v-card-text>
          <div class="pdf-button">
            <v-btn
              variant="text"
              @click="downloadOffer()"
              class="float-right"
            >Download tilbud<v-icon end>mdi-download</v-icon></v-btn>
            <LoadingSpinner
              :visible="isLoading"
              loadingMessage="Danner PDF..."
            />
          </div>
          <div>{{ tender.user.companyName }}</div>
          <div>{{ tender.description }}</div>
          <div>{{ tender.friendlyId }}</div>
          <h1 class="font-weight-bold">Tilbud</h1>
          <h3 class="mt-2">
            <p
              v-if="offer.sender.companyName && offerCvr"
              class="mb-1"
            >
              {{
                `${offer.sender.companyName}, CVR. ${offerCvr}`
              }}
            </p>
            <p
              v-if="offerPublishedDate && offer.sender.fullName"
              class="mb-1"
            >
              {{
                `Tilbud indsendt d. ${offerPublishedDate} af ${offer.sender.fullName}`
              }}
            </p>
            <br />
            <p class="mb-1">
              Tilbuddet opfylder samtlige krav i kravspecifikationen:
            </p>
            <p>
              <span v-if="offer.specificationAccepted">Ja, alle krav er opfyldt</span>
              <span
                class="text-error"
                v-else
              >Nej, alle krav er
                <span class="font-weight-bold">ikke</span> opfyldt</span>
            </p>
          </h3>
          <TcoCalculationSummary
            :questions="tcoCalculationQuestions"
            :tcoCalculationResult="tcoCalculationResult"
            class="my-10"
          />
          <div class="d-flex float-right align-center text-accent">
            <v-btn
              @click="expandingPanel(false)"
              variant="text"
              color="accent"
            >Fold ind</v-btn>|
            <v-btn
              @click="expandingPanel(true)"
              variant="text"
              color="accent"
            >Fold ud</v-btn>
          </div>
          <div
            v-for="tab in offerTabs"
            :key="tab.questionId"
          >
            <h2>{{ tab.title }} </h2>
            <br />
            <div
              v-for="section in tab.questions || []"
              :key="section.questionId"
            >
              <ReadOnlyTemplateQuestion
                v-if="questionnaire"
                :section="section"
                :question="section"
                :questionnaire="questionnaire"
                :level="1"
                :panelState="panelState"
                @panelsUpdate="panelsUpdate"
                :tender="tender"
              />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <LoadingSpinner
      :loadingMessage="loadingMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import {
  LoadingSpinner,
  Offer,
  Tender,
  dateUtils,
  useStore,
  DeepQuestionnaire,
  TemplateQuestionPanelState,
  ReadOnlyTemplateQuestion,
  downloadFrom,
  useBackend,
} from '@dims/components';
import TcoCalculationSummary from '../OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import excludedQuestions from '@/services/offerSummaryConfig';

const { tender, offer } = defineProps<{
  tender: Tender,
  offer: Offer }>();
const store = useStore();
const backend = useBackend();
const offerCvr = ref('');

const questionnaire = ref<DeepQuestionnaire | null>(null);
const isLoading = ref(false);
const loadingMessage = 'Vent venligst';
const panelState = ref<TemplateQuestionPanelState>(TemplateQuestionPanelState.INIT);

onMounted(async () => {
  await fetchQuestionnaire();
  offerCvr.value = await backend.supplierService.fetchCvrFromOrg(tender.agreementName, offer.sender.organizationNumber) ?? '';
});

async function downloadOffer() {
  if (tender.id) {
    isLoading.value = true;
    try {
      const archiveUrl = await store.backend.exportDataService.exportOffer(
        offer.id,
      );
      downloadFrom(archiveUrl);
    } catch (e) {
      console.error(e);
      store.setSnackbarText('Kan ikke downloade materiale.');
    } finally {
      isLoading.value = false;
    }
  }
}

async function fetchQuestionnaire() {
  isLoading.value = true;
  try {
    const deepQuestionnaire = await store.backend.deepQuestionnaireService
      .getReadonlyQuestionnaire(
        tender.id,
        'offer',
        offer.id,
      );
    questionnaire.value = deepQuestionnaire;
  } catch (e) {
    console.error(e);
    store.setSnackbarText('Error');
  }
  isLoading.value = false;
}

const offerPublishedDate = computed((): string => {
  const date = offer.publishedDate;
  return dateUtils.shortDateTimeFormat(date);
});

function expandingPanel(value: boolean) {
  panelState.value = value
    ? TemplateQuestionPanelState.EXPANDED
    : TemplateQuestionPanelState.COLLAPSED;
}

function panelsUpdate() {
  panelState.value = TemplateQuestionPanelState.CHANGED;
}

const offerTabs = computed(() => questionnaire.value?.questions ?? []);

// Questions used for offer status overview
const tcoCalculationQuestions = computed(() => questionnaire.value?.questions[2]?.questions?.filter(
  (q) => !excludedQuestions.includes(q.questionId),
) ?? []);

const tcoCalculationResult = computed(() => questionnaire.value?.tcoCalculationResult);

</script>
