<template>
  <v-radio-group v-model="selectedSolutionType">
    <template #label>
      <h3>Kategori</h3>
      <DimsTooltip>
        <p>Vælg den kategori der passer til dit indkøb.</p>
        <p>Du skal indledningsvist tage stilling til, om du har behov for at ét eller flere af nedenstående elementer er inkluderet i leverandørens tilbud:</p>
        <ul>
          <li>Tids- og milepælsplan</li>
          <li>Samarbejdsorganisation</li>
          <li>Teknisk løsningsbeskrivelse på opgaven</li>
        </ul>
        <p>Vælg som udgangspunkt <strong>'Kategori 1 - Kompetencekøb'</strong>, hvis du ikke har behov for ovenstående og <strong>'Kategori 2 - Opgavekøb'</strong> hvis du ønsker, at ét eller flere af elementerne indgår i leverandørens tilbud.</p>
        <p>Læs mere om kategorierne i de enkelte (i) herunder.</p>
        <p>Det er kun de leverandører, der svarer til dit valg af kategori, der kan afgive tilbud. Under fanen 'Leverandøroverblik' i topmenuen, kan du se leverandører godkendt på aftalen.</p>
        <p>Du kan ændre dit valg af kategori indtil du offentliggør indkøbet.</p>
      </DimsTooltip>
    </template>
    <v-radio
      class="ml-6"
      color="accent"
      :value="solutionType1"
    >
      <template #label>
        {{ solutionTypeLabel(solutionType1) }}
        <DimsTooltip>
          <p><strong>Kategori 1 - Kompetencekøb</strong></p>
          <p>Vælg Kompetencekøb hvis du har behov for at anskaffe definerede kompetencer/ressourcer til at arbejde i et fastsat omfang.</p>
          <p>Du beskriver opgaven, fastlægger krav til kompetencer og omfanget af ønsket indsats (antal timer).</p>
          <p>Leverandøren definerer relevante CV'er og angiver timepriser i sit tilbud.</p>
          <p>Kategori 1 vælges, hvis du ønsker tilbud baseret på:
            <ul>
              <li>Motiverede CV'er</li>
              <li>Timepriser</li>
            </ul>
          </p>
          <p>Du kan ændre dit valg af kategori senere.</p>
        </DimsTooltip>
      </template>
    </v-radio>
    <v-radio
      class="ml-6"
      color="accent"
      :value="solutionType2"
    >
      <template #label>
        {{ solutionTypeLabel(solutionType2) }}
        <DimsTooltip>
          <p><strong>Kategori 2 - Opgavekøb</strong></p>
          <p>Vælg denne kategori, hvis du har behov for at anskaffe kompetencer/ressourcer til at løse en nærmere beskrevet opgave.</p>
          <p>Du beskriver den opgave, du har behov for at få løst, og angiver krav til løsningsbeskrivelsen og resultatansvar.</p>
          <p>Leverandøren definerer relevant omfang og karakter af indsatsen i sit tilbud.</p>
          <p>Kategori 2 vælges, hvis du ønsker tilbud baseret på:
            <ul>
              <li>Leverandørens løsningsbeskrivelse (valgte elementer)</li>
              <li>Estimerede antal timer til opgaveløsning</li>
              <li>Timepriser</li>
            </ul>
          </p>
          <p>Du kan ændre valg af kategori senere.</p>
        </DimsTooltip>
      </template>
    </v-radio>
  </v-radio-group>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useStore1713 from '@/store/store1713';
import { SolutionType } from '@/models';

const store = useStore1713();

const solutionType1 = SolutionType.Category1;
const solutionType2 = SolutionType.Category2;

const selectedSolutionType = computed({
  get(): SolutionType | undefined {
    let solutionType;
    if (store.draftTender) {
      solutionType = store.draftTender.data.solutionType;
    }
    return solutionType;
  },
  set(solutionType: SolutionType | undefined) {
    if (store.draftTender && solutionType) {
      store.setDraftTender({
        ...store.draftTender,
        data: {
          ...store.draftTender.data,
          solutionType },
      });
    }
  },
});

function solutionTypeLabel(type: SolutionType): string {
  switch (type) {
    case SolutionType.Category1:
      return 'Kategori 1 - Kompetencekøb';
    case SolutionType.Category2:
      return 'Kategori 2 - Opgavekøb';
    default:
      return 'Unknown solution type';
  }
}

</script>

<style scoped>
h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
