<!--
  DO NOT EDIT THIS FILE

  File is duplicated across sites, so should be kept in sync
-->
<template>
  <OfferSandboxBase @reloadOfferSample="createSandboxOfferQuestionnaire()" backgroundColor="white">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-card class="pa-3 bg-canvas">
            <v-card-text>
              <div class="d-flex">
                <div>
                  <div class="d-flex align-center">
                    <h1 class="mt-0 mb-2 font-weight-bold">Udfyld dit tilbud</h1>
                    <v-chip color="darkPurple" class="ml-2">Leverandørvisning</v-chip>
                  </div>

                  <div class="mt-2 mb-7">
                    <p>
                      Kravbesvarelse og prisafgivelse herunder udgør dit tilbud
                    </p>
                    <p>
                      Udfyld dit tilbud ved (1) at besvare evalueringskravene,
                      (2) afgive priser og (3) bekræfte, at dit tilbud opfylder
                      samtlige af kundens krav.
                    </p>
                  </div>
                </div>
              </div>
              <v-card>
                <v-card-text class="pa-10">
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="1"
                        :complete="isSectionComplete([0, 1])"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Tilbudsafgivelse</h2>
                      <p>
                        Forud for tilbudsafgivelsen skal du orientere dig i kundens offentliggjorte materiale, som du finder under "overblik"
                      </p>
                      <p>
                        Du udfylder dit tilbud ved at besvare kundens behovsopgørelse, tilbyde relevante konsulenter (herunder uploade CV) samt afgive priser.
                      </p>
                      <p>
                        Du vil løbende blive guidet til at udfylde de relevante felter og vejledt om, hvor de finde de relevante informationer i kundens offentliggjorte materiale.
                      </p>
                      <IconExplanation :isCustomer="false" />
                      <v-dialog v-model="offerFormDialog" max-width="95%">
                        <template #activator="{ props }">
                          <ArrowButton
                            v-bind="props"
                            class="mt-2"
                            :class="isSectionComplete(priceSections) ? 'secondary' : ''"
                          >Udfyld tilbud
                          </ArrowButton>
                        </template>
                        <v-card>
                          <OfferDetailsForm
                            v-if="offerQuestionnaire"
                            :tender="tender"
                            :offerQuestionnaire="offerQuestionnaire"
                            :tabType="'SupplierOffer'"
                            :readonly="true"
                            @closeDialog="closeOfferFormDialog"
                          />
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus
                        :label="2"
                        :complete="false"
                      />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Kundens kravspecifikation</h2>
                      <div>
                        Bekræft om dit tilbud opfylder samtlige krav i kundens
                        kravspecifikation.
                      </div>
                      <v-radio-group
                        hide-details
                        disabled
                        density="compact"
                      >
                        <v-radio :value="true">
                          <template #label>
                            <small>Ja, alle krav er opfyldt</small>
                          </template>
                        </v-radio>
                        <v-radio :value="false">
                          <template #label>
                            <small>
                              Nej, alle krav er
                              <span class="font-weight-bold">ikke</span>
                              opfyldt
                            </small>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="3" :complete="false" />
                    </v-col>
                    <v-col>
                      <p>
                        Du kan her uploade det ESPD, som ligger til grund for
                        din optagelse på aftalen.
                      </p>
                      <v-btn class="primary-button-with-arrow">
                        <span>Upload ESPD-dokument</span>
                        <v-icon size="small">mdi-upload</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-divider class="my-8"></v-divider>
                  <v-row>
                    <v-col cols="auto">
                      <StepStatus :label="4" :complete="false" />
                    </v-col>
                    <v-col>
                      <h2 class="mt-0 mb-2">Se og send tilbud</h2>
                      <p>Færdiggør trin 1, 2 og 3, for at sende dit tilbud</p>
                      <v-dialog
                        persistent
                        scrollable
                        max-width="80%"
                      >
                        <template #activator="{ props }">
                          <ArrowButton
                            disabled
                            v-bind="props"
                            class="mt-2"
                          >Se og send tilbud</ArrowButton>
                        </template>
                        <v-card>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <TcoCalculationSummary
            :questions="questions"
            :tcoCalculationResult="tcoCalculationResult"
            cardStyle="#fff9ef"
            tableStyle="transparent"
          />
        </v-col>
      </v-row>
    </v-container>
  </OfferSandboxBase>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { Tender, OfferSandboxBase, useBackend, DeepQuestionnaireRoot, OfferDetailsForm, ReadOnlyQuestionnaireWrapper } from '@dims/components';
import TcoCalculationSummary from './OfferDetails/TcoCalculation/TcoCalculationSummary.vue';
import { setSupplierPriceTabs } from '@/services/questionnaireTabConfig';
import excludedQuestions from '@/services/offerSummaryConfig';

const { tender } = defineProps<{ tender: Tender }>();
const backend = useBackend();
const offerFormDialog = ref(false);
const offerQuestionnaire = ref<DeepQuestionnaireRoot>();
const priceSections = ref<number[]>([]);

async function createSandboxOfferQuestionnaire() {
  const questionnaire = await backend.deepQuestionnaireService.previewQuestionnaire(
    tender.id,
    'offer',
  );
  offerQuestionnaire.value = new ReadOnlyQuestionnaireWrapper(
    questionnaire,
    backend.deepQuestionnaireService,
  );
  priceSections.value = setSupplierPriceTabs(
    offerQuestionnaire.value.questionnaire,
  );
  priceSections.value = priceSections.value.filter((section) => section !== -1);
}

const tcoCalculationResult = computed(() => offerQuestionnaire.value?.questionnaire.tcoCalculationResult);

// Questions used for offer status overview
// Exclude the first question (general information) from the summary
const questions = computed(
  () => offerQuestionnaire.value?.questionnaire.questions.filter((x, idx) => priceSections.value.includes(idx) && x.questions).flatMap((x) => x.questions ?? [])
    .filter((q) => !excludedQuestions.includes(q.questionId)) ?? [],
);

function isSectionComplete(sections: number[]) {
  const topLevelQuestions = offerQuestionnaire.value?.questionnaire.questions;
  return topLevelQuestions
    ? sections.every((section) => topLevelQuestions[section]?.questions?.every((q) => q.complete))
    : false;
}

function closeOfferFormDialog() {
  offerFormDialog.value = false;
}

</script>
